/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as ramda from 'ramda';
import { analysePageReducers } from './reducers/analyse-page';
import { comparablesReducers } from './reducers/comparables';
import { Note, File, ReportList, UpdateStatus, GetGeoCodes, AveragePricesStat, Users, LocalMarket, SquareUnit, LocalArea, Station, School,
  ComparablesItem, ComparablesFilter, FindOutMoreNote, HubspotContactsItem, HubspotListItem, ScenarioStatus, InputDataContextInterface,
  ScenarioShort, Timeline } from '../../../../types';
export * from './actions';

export interface AppState {
  openedPopup: {
    popupName: string | null,
    popupData: any
  },
  analysePage: {
    scenarioCreatedAt?: Date,
    termsAndConditionsAcceptance?: boolean,
    riskProfile: number,
    currentMenu: string,
    addressAutofill: string[],
    isAutofillClicked: boolean,
    isBlockApisUsage: boolean,
    isBasic: boolean,
    isFormChanged: boolean,
    showInfoPanel: boolean,
    selectorSq_ft_Sq_m: SquareUnit,
    locationService?: {
      getGeoCodes?: GetGeoCodes
    },
    comparablesService: {
      getList: ComparablesItem[],
      getMaxComparables: number,
      selectedComparables?: ComparablesItem[],
      isShowAll: boolean,
      comparablesFilter: ComparablesFilter,
      page: number,
      limit: number,
      maxComparables: number,
    },
    editingData: InputDataContextInterface,
    localMarket?: LocalMarket,
    localArea?: {
      data?: LocalArea;
    };
    localAreaStations: Station[];
    localAreaSchools: School[];
  },
  otherCapitalDetails: any,
  sensitivityAnalyses: any[],
  resultData: any,
  loading: boolean,
  error: boolean,
  fundingCount: number,
  equityCount: number,
  projectId?: string;
  scenarioId?: string;
  scenarioShortId?: number;
  projectName?: string;
  notes: Note[];
  files: File[];
  reportList?: ReportList;
  reportListAll?: ReportList;
  reportListStatus: UpdateStatus;
  reportListAllStatus: UpdateStatus;
  averagePricesStat?: AveragePricesStat;
  teaserReportId?: string;
  findOutMoreNote?: FindOutMoreNote;
  shareNote?: string[];
  hubspotLists?: HubspotListItem[];
  hubspotContacts?: HubspotContactsItem[];
  users?: Users;
  isOwned: boolean;
  loadStatus: UpdateStatus;
  showAddress?: boolean;
  scenarioName?: string;
  scenarioStatus?: ScenarioStatus;
  leadId?: string;
  leadName?: string;
  templateId?: string;
  presetConstructionId?: string;
  presetPurchaseId?: string;
  presetFinancingId?: string;
  allScenarios: ScenarioShort[];
  leadFiles: File[];
  leadImages: File[];
  assetsId?: string;
  isSaving: boolean;
  timeline?: Timeline[];
}

export interface Tabs {
  title: string,
  selectedComparables?: ComparablesItem[],
  comparablesFilter?: ComparablesFilter,
}

export const initialState: AppState = {
  openedPopup: {
    popupName: null as string | null,
    popupData: null as any | null,
  },
  analysePage: {
    termsAndConditionsAcceptance: false,
    riskProfile: 5,
    currentMenu: 'Site & purchase' as string,
    addressAutofill: [],
    isAutofillClicked: false,
    isBasic: true,
    isFormChanged: false,
    isBlockApisUsage: false,
    showInfoPanel: true,
    selectorSq_ft_Sq_m: 'Sq.m',
    comparablesService: {
      getList: [],
      selectedComparables: [],
      isShowAll: true,
      comparablesFilter: {
        type: [],
        bedroom: [],
        area: {
          value: 'default',
          radius: undefined,
        },
        price: {
          min: 0,
          max: 100000000,
        },
      },
      maxComparables: 0,
      getMaxComparables: 0,
      page: 0,
      limit: 0,
    },
    localAreaStations: [],
    localAreaSchools: [],
    editingData: {
      project_name: '',
      site_address: '',
      country: 'SWE',
      currency: 'SEK',
      type_of_project: '',
      project_start_date: '',
      site_type: '',
      buyer_type: '',
      stamp_duty_type: '',
      planning_status: '',
      planning_application_url: '',
      purchase_costs_array: [],
      units_array: [],
      contract_type: '',
      construction_type: '',
      finish_type: '',
      payment_type: '',
      construction_costs_array: [],
      funding_array: [],
      sales_strategy: '',
      completion_date: '',
      start_of_sales: '',
      sales_complete: '',
      show_home_unit: '',
      sales_costs_array: [],
      contingency_array: [],
      project_start_purchase: 45,
      project_purchase_construction: 15,
      residential_valuation_type: 'bm',
      createdAt: '',
    },
  },
  otherCapitalDetails: [],
  sensitivityAnalyses: [],
  resultData: '',
  loading: false,
  error: false,
  fundingCount: 0,
  equityCount: 0,
  notes: [],
  files: [],
  reportListStatus: 'new',
  reportListAllStatus: 'new',
  loadStatus: 'new',
  hubspotLists: [],
  hubspotContacts: [],
  allScenarios: [],
  leadFiles: [],
  leadImages: [],
  isOwned: true,
  isSaving: false,
};

const calculateSlice = createSlice({
  name: 'calculate',
  initialState,
  reducers: {
    ...analysePageReducers,
    ...comparablesReducers,
    setPopupOpen(state, action) {
      state.openedPopup = action.payload;
    },
    setEditingData(state, action: PayloadAction<Partial<InputDataContextInterface>>) {
      state.analysePage.editingData = { ...state.analysePage.editingData, ...action.payload };

      const totalFloorAreaChanged = 'total_floor_area' in action.payload;
      const hasTotalFloorAreaCost = state.analysePage.editingData?.construction_costs_array.some((item) => 'total_floor_area_cost' in item && item.total_floor_area_cost);

      if ((state.analysePage.editingData?.delayed_payment_purchase || 0) > (state.analysePage.editingData?.site_purchase_price || 0)) {
        state.analysePage.editingData.delayed_payment_purchase = state.analysePage.editingData?.site_purchase_price;
      }

      if (totalFloorAreaChanged && hasTotalFloorAreaCost) {
        state.analysePage.editingData.construction_costs_array = state.analysePage.editingData.construction_costs_array.map((item) => ({
          ...item,
          total_cost: 'total_floor_area_cost' in item && item.total_floor_area_cost ?
            (action.payload.total_floor_area || 0) * (item.total_floor_area_cost || 0) : item.total_cost,
        }));
      }
    },
    setSensitivityAnalyses(state, action) {
      state.sensitivityAnalyses = action.payload;
    },
    setLocalArea(state, action) {
      state.analysePage.localArea = action.payload;
    },
    setLocalAreaStations(state, action) {
      state.analysePage.localAreaStations = action.payload;
    },
    setLocalAreaSchools(state, action) {
      state.analysePage.localAreaSchools = action.payload;
    },
    setResultData(state, action) {
      state.resultData = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setReqError(state, action) {
      state.error = action.payload;
    },
    setFundingCount(state, action) {
      state.fundingCount = action.payload;
    },
    setEquityCount(state, action) {
      state.equityCount = action.payload;
    },
    setLocalMarket(state, action) {
      state.analysePage.localMarket = { ...(state.analysePage.localMarket || {}), ...action.payload };
    },
    setOtherCapitalDetails(state, action) {
      state.otherCapitalDetails = action.payload;
    },
    setProjectId(state, action: PayloadAction<string>) {
      state.projectId = action.payload;
    },
    setScenarioId(state, action: PayloadAction<string>) {
      state.scenarioId = action.payload;
    },
    setScenarioShortId(state, action: PayloadAction<number | undefined>) {
      state.scenarioShortId = action.payload;
    },
    setProjectName(state, action: PayloadAction<string>) {
      state.projectName = action.payload;
    },
    setNotes(state, action: PayloadAction<Note[]>) {
      state.notes = action.payload;
    },
    setFiles(state, action: PayloadAction<File[]>) {
      state.files = action.payload;
    },
    setHubspotLists(state, action: PayloadAction<[]>) {
      state.hubspotLists = action.payload;
    },
    setHubspotContacts(state, action: PayloadAction<[]>) {
      state.hubspotContacts = action.payload;
    },
    setReportList(state, action: PayloadAction<ReportList>) {
      state.reportList = action.payload;
    },
    setReportListAll(state, action: PayloadAction<ReportList>) {
      state.reportListAll = action.payload;
    },
    setReportListStatus(state, action: PayloadAction<UpdateStatus>) {
      state.reportListStatus = action.payload;
    },
    setReportListAllStatus(state, action: PayloadAction<UpdateStatus>) {
      state.reportListAllStatus = action.payload;
    },
    setUsers(state, action: PayloadAction<Users>) {
      state.users = action.payload;
    },
    setInitialState(state) {
      for (const key of Object.keys(state)) {
        if (initialState[key] === undefined) {
          delete state[key];
        } else {
          state[key] = ramda.clone(initialState[key]);
        }
      }
    },
    setInitialStateKeepReportList(state) {
      const reportListFields = new Set(['reportList', 'reportListAll', 'reportListStatus', 'reportListAllStatus']);

      for (const key of Object.keys(state).filter((field) => !reportListFields.has(field))) {
        if (initialState[key] === undefined) {
          delete state[key];
        } else {
          state[key] = ramda.clone(initialState[key]);
        }
      }
    },
    setAveragePricesStat(state, action: PayloadAction<AveragePricesStat | null>) {
      state.averagePricesStat = action.payload || undefined;
    },
    setTeaserReportId(state, action: PayloadAction<string | null>) {
      state.teaserReportId = action.payload || undefined;
    },
    setFindOutMoreNote(state, action: PayloadAction<FindOutMoreNote | null>) {
      state.findOutMoreNote = action.payload || undefined;
    },
    setShareNote(state, action: PayloadAction<string[] | null>) {
      state.shareNote = action.payload || undefined;
    },
    setIsOwned(state, action: PayloadAction<boolean>) {
      state.isOwned = action.payload;
    },
    setLoadStatus(state, action: PayloadAction<UpdateStatus>) {
      state.loadStatus = action.payload;
    },
    setShowAddress(state, action: PayloadAction<boolean | undefined>) {
      state.showAddress = action.payload;
    },
    setScenarioName(state, action: PayloadAction<string | undefined>) {
      state.scenarioName = action.payload;
    },
    setScenarioStatus(state, action: PayloadAction<ScenarioStatus | null | undefined>) {
      state.scenarioStatus = action.payload || undefined;
    },
    setLeadId(state, action: PayloadAction<string | undefined>) {
      state.leadId = action.payload;
    },
    setLeadName(state, action: PayloadAction<string | undefined>) {
      state.leadName = action.payload;
    },
    setTemplateId(state, action: PayloadAction<string | undefined>) {
      state.templateId = action.payload;
    },
    setPresetConstructionId(state, action: PayloadAction<string | undefined>) {
      state.presetConstructionId = action.payload;
    },
    setPresetPurchaseId(state, action: PayloadAction<string | undefined>) {
      state.presetPurchaseId = action.payload;
    },
    setPresetFinancingId(state, action: PayloadAction<string | undefined>) {
      state.presetFinancingId = action.payload;
    },
    setAllScenarios(state, action: PayloadAction<ScenarioShort[] | undefined>) {
      state.allScenarios = action.payload || [];
    },
    setLeadFiles(state, action: PayloadAction<File[]>) {
      state.leadFiles = action.payload;
    },
    setLeadImages(state, action: PayloadAction<File[]>) {
      state.leadImages = action.payload;
    },
    setAssetsId(state, action: PayloadAction<string>) {
      state.assetsId = action.payload;
    },
    setTimeline(state, action: PayloadAction<Timeline[]>) {
      state.timeline = action.payload;
    },
    setIsSaving(state, action: PayloadAction<boolean>) {
      state.isSaving = action.payload;
    },
  },
});

export const {
  setPopupOpen, setAnalyseMenu, setIsFormChanged, setIsBasic, setEditingData,
  setLocationGeoCodes, setSensitivityAnalyses, setLocalArea, setResultData,
  setLoading, setReqError, setFundingCount, setEquityCount, setLocalAreaStations,
  setLocalAreaSchools, setSelectedComparables, setScenarioCreatedAt, setComparablesList,
  setComparablesFilter, setIsShowAll, setSelectorSq_ft_Sq_m, setUnitsArray, setLocalMarket,
  setOtherCapitalDetails, setProjectId, setScenarioId, setProjectName,
  setComparablesPage, setComparablesLimit, setNotes, setFiles, setReportList,
  setReportListAll, setReportListStatus, setReportListAllStatus, setInitialState,
  setShowInfoPanel, setIsBlockApisUsage, setAddressAutofillList, setIsAutofillClicked,
  setAveragePricesStat, setTeaserReportId, setFindOutMoreNote, setUsers, setRiskProfile, setHubspotLists,
  setHubspotContacts, setShareNote, setTermsAndConditionsAcceptance, setMaxComparables, setIsOwned,
  setLoadStatus, setShowAddress, setScenarioName, updateComparablesFilter, setLeadId, setLeadName, setScenarioStatus,
  setScenarioShortId, setTemplateId, setPresetConstructionId, setPresetPurchaseId, setPresetFinancingId,
  setAllScenarios, setLeadFiles, setLeadImages, setAssetsId, setTimeline, setInitialStateKeepReportList,
  setIsSaving,
} = calculateSlice.actions;

export default calculateSlice.reducer;
