interface OtherProps {
  returnError?: boolean;
}

export const apiFetch = async (url: string, opts: RequestInit & OtherProps = {}, noContentType = false) => {
  return fetch(`/api/${url}`, {
    credentials: 'include',
    ...opts,
    headers: {
      ...(!noContentType ? { 'Content-Type': 'application/json' } : {}),
      ...(opts.headers || {}),
    },
  }).then((res) => {
    if (res.ok) {
      return res.status === 204 ? undefined : res;
    } else {
      console.error(`Got bad response from ${url}: ${res.status} ${res.statusText}`);
      if ( opts.returnError ) {
        return res;
      }
    }
  }).then((res) => res?.json?.());
};

export default apiFetch;
